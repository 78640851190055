.cashBackText {
  font-size: 16px;
  color: #000;
  line-height: 36px;
  font-weight: 400;
  @media (max-width: 768px) {
    margin: 0 18px;
    text-align: center;
  }
  @media (max-width: 550px) {
    font-size: 14px;
    line-height: 24px;
  }
}
