.allaModalBlock {
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999999;
  padding:0 15px;
}

.content {
  padding: 35px 60px;
  position: relative;
  max-width: 480px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  box-shadow: 0 16px 32px rgb(160 182 182 / 24%);

  @media(max-width:450px) {
    padding: 35px 15px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.closeButton {
  width: 32px;
  height: 32px;
  background: url(/images/close-icon.svg) center/30px no-repeat;
  cursor: pointer;
  float: right;
}
