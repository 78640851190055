.popupCustomerExisted {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: 100%;
}

.container {
  max-width: 480px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0 16px 20px rgba(160, 182, 182, 0.3);
  border-radius: 30px;
  padding: 60px 40px;
}

.header {
  font-weight: 600;
  font-size: 36px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.015em;
}

.wrapperButton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}

.wrapper {
  margin-bottom: 20px;
}

.button {
  padding: 10px 42px;
  color: #ffffff;
  border: 2px solid #4faf4d;
  background: #4faf4d;
  border-radius: 23px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
  max-width: 180px;
  cursor: pointer;
}

.button:hover,
.button:active {
  color: #4faf4d;
  background-color: #ffffff;
}

.input {
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.015em;
  padding: 16px 16px 12px 16px;
  background: transparent;
  width: 100%;
  margin-bottom: 16px;
}

.input::placeholder {
  color: #000000;
}

.buttonLink {
  font-weight: 800;
  letter-spacing: 0.015em;
  color: #4faf4d;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.buttonLine {
  text-decoration-line: underline;
  font-size: 18px;
  line-height: 140%;
}

@media (max-width: 700px) {
  .container {
    max-width: 400px;
  }

  .header {
    font-size: 24px;
  }

  .wrapperButton {
    padding: 0;
  }

  .button {
    padding: 5px 30px;
  }
}

@media (max-width: 450px) {
  .container {
    max-width: 280px;
    padding: 20px;
  }

  .wrapperButton {
    margin-top: 30px;
  }

  .button {
    font-size: 16px;
    padding: 5px 20px;
  }
  .wrapper {
    margin-bottom: 10px;
  }
}
