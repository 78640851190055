.filterItem {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #f3f8f8;
  background: #f3f8f8;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover,
  &:active {
    border: 1px solid #4faf4d;
  }
}

.filterActive {
  border: 1px solid #4faf4d;
}

.filterImg {
  max-width: 24px;
}

.filterText {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.015em;
}

.filterDelete {
  font-size: 16px;
  line-height: 28px;
  color: #e56966;
}
