.item,
.wrapper,
.addProduct {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.item {
  padding-bottom: 5px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 30px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    background-color: #c7d0d6;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 0;
    margin-bottom: 10px;
    &::after {
      content: '';
      bottom: -10px;
    }
    &:last-child {
      margin-bottom: 0;
      &::after {
        display: none;
      }
    }
  }
}

.wrapperBlock {
  align-self: center;
  align-items: center;
  gap: 20px;
  width: 250px;

  @media (max-width: 768px) {
    align-self: flex-start;
    margin: 0 0 0 -15px;
    width: 100%;
  }
}

.image {
  height: 80px;
}

.title {
  margin-left: 20px;
  align-self: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.015em;
  max-width: 300px;

  @media (max-width: 768px) {
    margin-left: 10px;
    font-size: 16px;
    line-height: 19px;
  }
}

.addProduct {
  //margin-right: 20px;
  padding: 5px 17px;
  box-sizing: border-box;
}

.addProductMobile {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 50px;
    left: 40px;
  }
}

.addProductDesktop {
  @media (max-width: 768px) {
    //display: none;
  }
}

.cost {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.015em;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  left: 150px;
}

.remove {
  cursor: pointer;
}

.wrapperTop {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
    justify-content: space-between;
  }
}

.preloader {
  width: 100%;
  position: absolute;
  top: -10px;
  right: -25px;
}
