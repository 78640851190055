.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.swiperCustomPagination {
  position: absolute;
  bottom: -30px !important;
  z-index: 15;
  left: 50% !important;
  width: auto !important;
  transform: (translateX(-50%));

  .swiperPaginationBulletActive {
    opacity: 1;
    background: var(--vitaliv-orange-color);
  }

  &.orange {
    .swiperPaginationBulletActive {
      background: var(--vitaliv-orange-color);
    }
  }

  @media (max-width: 860px) {
    //display: none;
  }
}

.slider__wrapper {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  :global(.swiper-button-next, .swiper-button-prev) {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    margin-top: 0;
    color: white;
    font-weight: bold;
    font-size: 20px;
    user-select: none;
    -webkit-user-select: none;
  }
}

.slider {
  position: relative;

  & .slider_button_prev,
  & .slider_button_next {
    padding: 16px;
    height: auto;
    border-radius: 100px;
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    margin: 0 10px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    user-select: none;
    -webkit-user-select: none;
    z-index: 15;

    &::after {
      display: none;
    }
    @media (max-width: 860px) {
      display: none;
    }
  }

  & .slider_button_prev {
    background: url('/images/arrowL.svg') center no-repeat, #f3f8f8;
    left: -50px;
  }

  & .slider_button_next {
    background: url('/images/arrowR.svg') center no-repeat, #f3f8f8;
    right: -50px;
  }
}

.slider_button_next {
  right: 0;
  background: url('/images/arrowR.svg') center no-repeat, #f3f8f8;
  padding: 16px;
  height: auto;
  border-radius: 100px;

  &::after {
    display: none;
  }
}

.slider__item {
  flex: 0 0 33.3333% !important;
  min-height: 250px;
  color: black;
  padding: 0 10px;
}

.customPaginationBullets {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: var(--blue-grey-color-150);
  border-radius: 50%;
  margin-right: 12px;
}
