.button {
  text-transform: uppercase;
  text-align: center;
  background: var(--vitaliv-green-color);
  border: 2px solid var(--vitaliv-green-color);
  border-radius: 5em;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5em;

  margin: 20px auto 0;
  color: #fff;
  cursor: pointer;
  transition: all 150ms ease;
  margin-bottom: 10px;

  &:hover {
    text-decoration: none;
    background-color: var(--vitaliv-orange-color);
    border-color: var(--vitaliv-orange-color);
  }

  &.secondary {
    background-color: transparent;
    color: var(--vitaliv-green-color);

    &:hover {
      background-color: var(--vitaliv-green-color);
      border: 2px solid var(--vitaliv-green-color);
      color: #fff;
    }
  }

  &.disabledButton {
    background-color: var(--blue-grey-color-150);
    border: 2px solid var(--blue-grey-color-150);
    pointer-events: none;
    color: #fff;
  }

  &.disabledButtonWithoutBG {
    pointer-events: none;
  }
}

.button__discount_code {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 2em !important;
}
