.preloaderWrapperFull {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  z-index: 1001;
}

.preloaderWrapperPart {
  // position: absolute;
  margin-left: -15px;
}

.loader {
  position: relative;
  top: 50%;
  left: 50%;
  border: 3px solid #4faf4d;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid #eae4ea;
  animation: loader 3s linear infinite;
}

.loader:before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  animation: loader 2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
