.wrapper {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.swiperMain {
  padding-bottom: 50px !important;
}

.swiper-pagination-bullet-active {
  background: #4faf4d !important;
}

.header {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.015em;
  color: #181818;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 400px) {
    font-size: 24px;
  }
}

.container {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  max-width: 325px;
  min-height: 185px;
  cursor: pointer;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 10px;
  letter-spacing: 0.015em;
}

.address {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0.015em;
  text-transform: uppercase;
}

// .slider_button_prev,
// .slider_button_next {
//   padding: 16px;
//   height: auto;
//   border-radius: 100px;
//   cursor: pointer;
//   position: absolute;
//   top: 40%;
//   width: auto;
//   margin: 0 10px;
//   color: white;
//   font-weight: bold;
//   font-size: 20px;
//   user-select: none;
//   -webkit-user-select: none;
//   z-index: 15;

//   &::after {
//     display: none;
//   }
//   @media (max-width: 860px) {
//     display: none;
//   }
// }

// .slider_button_prev {
//   background: url('/images/arrowL.svg') center no-repeat, #f3f8f8;
//   left: -50px;
// }

// .slider_button_next {
//   background: url('/images/arrowR.svg') center no-repeat, #f3f8f8;
//   right: -50px;
// }
