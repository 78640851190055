.totalContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 20px;
  padding-top: 10px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    height: 1px;
    background-color: #c7d0d6;
  }

  @media (max-width: 1023px) {
    margin: 20px 18px 20px 18px;
  }
  @media (max-width: 639px) {
    margin: 20px 18px 5px 18px;
    justify-content: center;
    gap: 20px;
    &::after {
      display: none;
    }
  }
}

.totalContainerMobile {
  @media (max-width: 639px) {
    justify-content: space-between;
    margin: 0;
    padding-top: 0;
    &::after {
      display: block;
    }
  }
}

.total {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.015em;
  z-index: 1;
  background-color: #fafafa;

  @media (max-width: 768px) {
    line-height: 130%;
  }
}

.totalTitleMobile {
  @media (max-width: 639px) {
    font-size: 18px;
    line-height: 150%;
  }
}
