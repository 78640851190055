.button {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--vitaliv-green-color);
  padding: 12px 10px;
  border: 2px solid var(--vitaliv-green-color);
  box-sizing: border-box;
  border-radius: 50px;
  width: 100%;
}

.button:hover {
  color: #ffffff;
  background: var(--vitaliv-green-color);
}
