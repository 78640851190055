.orderItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 32px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    background-color: #c7d0d6;

    @media (max-width: 479px) {
      bottom: 45px;
    }
  }

  @media (max-width: 479px) {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
}

.orderLabel {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding-right: 10px;

  @media (max-width: 479px) {
    background-color: transparent;
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.orderPic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 8px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.orderName {
  display: inline-block;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.015em;
}

.orderQnt {
  display: inline-block;
  font-size: 18px;
  line-height: 40px;
  margin-left: 10px;
  letter-spacing: 0.015em;

  @media (max-width: 479px) {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
  }
}

.orderPrices {
  padding-left: 10px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 479px) {
    flex: 1 1 100%;
    max-width: 100%;
    background-color: transparent;
  }
}

.orderPrice {
  font-size: 18px;
  line-height: 40px;
  font-weight: 600;

  @media (max-width: 479px) {
    margin-top: 10px;
  }
}
