.card {
  background: #fff;
  //padding: 24px;
  border-radius: 2px;
  border: 1px solid var(--blue-grey-color-50);
  position: relative;
  transition: 0.25s ease-in;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  max-width: 358px;
  @media (max-width: 648px) {
    max-width: 100%;
  }

  &:hover {
    //box-shadow: 0 16px 32px rgba(160, 182, 182, 0.24);
  }
}

.postMiniSliderTop {
  display: none;
  position: absolute;
  z-index: 888;
  top: 15px;
  left: 23px;
  span {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: var(--blue-grey-color-150);
    border-radius: 50%;
    margin-right: 12px;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  &.orange {
    span.active {
      background: var(--vitaliv-orange-color);
    }
  }
}

.swiper-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.categoryText {
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 7px 10px 4px;
  border-radius: 16px;
}

.selectBlock {
  min-width: 140px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 479px) {
    flex-direction: column;
    min-width: 65%;
  }
}

.selectBlockLoader {
  justify-content: center;
}

.selectMinus,
.selectPlus,
.selectNumber {
  width: 40px;
  color: var(--vitaliv-green-color);
}

.selectMinus,
.selectPlus {
  font-size: 30px;
  cursor: pointer;
}

.desktopSliderImage {
  width: auto;
  height: auto;
  max-height: 508px;
  display: block;
  margin: 48px auto 3px;
  object-fit: cover;
}

.subtitleShop {
  color: #3f513f !important;
  margin: 20px 0 10px !important;
}

.textTitleShop {
  font-size: 16px !important;
  color: #758475 !important;
}

.wrapperButtonSlider {
  margin-top: -20px;
}

.imageBlock {
  max-width: 100%;
  //max-height: 250px;
  border-bottom: 1px solid #e6f1f1;
  box-sizing: border-box;
  border-radius: 2px 2px 0 0;
}

.imagesNew {
  background: linear-gradient(180deg,#FDF4DB 0%,rgba(253,244,219,.46) 100%) !important;
}

.imagesBlockNew {
  border: none;
}

.wrapperBlockPriceNew {
  display: flex;
  gap: 35px;
  align-items: baseline;
}

.textNew {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3F513F;

  @media (min-width: 640px) {
    font-size: 14px;
  }
}

.textPriceNew {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #758475;

  @media (min-width: 640px) {
    font-size: 14px;
  }
}

.priceNew {
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #232E23;

  @media (min-width: 640px) {
    font-size: 28px;
  }
}

.priceNewTwo {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #758475;

  @media (min-width: 640px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.headerNew {
  font-size: 20px!important;
  text-transform: uppercase;
  font-weight: 700!important;

  @media (min-width: 640px) {
    font-size: 24px!important;
  }
}

.goalIcons {
  position: absolute;
  z-index: 100;
  width: 100%;
  max-height: 250px;
  height: 100%;
  display: inline-block;
}

.goalIconsError {
  height: 46%;
  top: -30px;
}

.desktopImage {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.mobileImage {
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
}

.images {
  display: flex;
  overflow: hidden;
  background: #f3f8f8;
}

.wrapperBlock {
 // padding: 0 20px;
  //margin-top: 20px;
}

.header {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-top: 15px;

  cursor: pointer;
}

.subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #73818a;
  margin: 5px 0;
}

.textTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #73818a;
  //margin-bottom: 10px;
}

.wrapperText {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.text {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.textPrice {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #73818a;
}

.wrapperButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  gap: 10px;
  padding: 0 0 30px;
}

.cost {
  font-weight: 800;
  white-space: nowrap;
}

.costPrice {
  text-decoration-line: line-through;
  color: #e56966;
  white-space: nowrap;
}

.buttonDetails {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.textDetails {
  font-weight: 800;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.015em;
  color: #4faf4d;
}

.buttonViewCart {
  width: 100%;
}
