.wrapper {
  grid-column: 2;
  margin-top: 20px;

  @media (max-width: 650px) {
    grid-column: 1;
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.015em;
}

.button {
  color: #4faf4d;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}