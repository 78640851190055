.quantitySelector {
  display: flex;
  width: 100%;
  min-width: 90px;
  height: 30px;
  color: var(--vitaliv-green-color);
  font-size: 16px;
  line-height: 1em;
  user-select: none;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--vitaliv-green-color);
  border-radius: 50px;
}

.quantitySelectorCard {
  height: 51px;
  display: flex;
  width: 100%;
  min-width: 90px;
  color: var(--vitaliv-green-color);
  font-size: 20px;
  line-height: 1em;
  user-select: none;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--vitaliv-green-color);
  border-radius: 50px;
}

// .quantitySelectorDisabled {
//   border: 2px solid var(--blue-grey-color-150);
//   color: var(--blue-grey-color-150);
// }

.quantitySelectorControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 700;
}

.quantitySelectorMinus,
.quantitySelectorPlus {
  font-size: 30px;
  cursor: pointer;
  padding-top: 2px;
  color: var(--vitaliv-green-color);
  font-weight: 300;
}

// .quantitySelectorMinus:disabled,
// .quantitySelectorPlus:disabled {
//   color: var(--blue-grey-color-150);
// }

.quantitySelectorMinus,
.quantitySelectorPlus,
.quantitySelectorDisplay {
  width: 30px;
}

.quantitySelectorMinus {
  padding-left: 5px;
}

.quantitySelectorPlus {
  padding-right: 5px;
}

.button {
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  padding: 12px 20px;
  border: 2px solid var(--vitaliv-green-color);
  box-sizing: border-box;
  border-radius: 23px;
  width: 100%;
  background: url("/images/cartIcon1.svg") 22% 35% no-repeat ,var(--vitaliv-green-color);
}

.button:hover {
  color: var(--vitaliv-green-color);
  background: url("/images/cartIcon2.svg") 22% 35% no-repeat, #FFFFFF;
}
