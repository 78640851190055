.statusContainer,
.status {
  display: flex;
}

.statusText {
  margin-right: 5px;
}

.statusImg {
  margin-right: 10px;
}

.statusLink {
  color: #008bc8;
  text-decoration: underline;
  cursor: pointer;
}

.statusNone {
  display: none;
}