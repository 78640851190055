.breadcrumbs {
  font-size: 14px;
  max-width: 1400px;
  padding: 40px 20px 0;

  @media (min-width: 1080px) {
    margin: 0 auto;
    zoom: 0.8;
  }

  @media (min-width: 1550px) {
    zoom: 1;
    max-width: 1300px;
  }

  @media (max-width: 769px) {
    margin-left: 20px;
    padding-top: 20px;
  }

  li {
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      padding-right: 8px;
      margin-right: 8px;

      &:after {
        content: '';
        background: url("/images/arrowB.svg") center no-repeat;
        width: 4px;
        height: 8px;
        position: absolute;
        right: -3px;
        top: 5px;
      }
    }

    a, span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #999999;
      cursor: pointer;
    }

    a {
      text-decoration: none;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}

.noLink {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #232E23 !important;
}

.buttonBack {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #4FAF4D;
  position: relative;
}

.buttonBack:before {
  content: '';
  background: url("/images/Arrow5.svg") center no-repeat;
  width: 14px;
  height: 8px;
  position: absolute;
  left: -20px;
  top: 1px;
}