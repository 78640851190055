// .inputLabel {
//   display: none;
//   position: absolute;
//   top: 0;
//   left: 0;
// }

.placeholder {
  display: block;
  line-height: 0;
  transform: translate(15px, -44px);
  color: var(--dark-gray);
  background-color: #ffffff;
  font-size: 16px;
}

.textEmail,
.textEmail:focus {
  text-transform: lowercase;
}

.placeholderError {
  color: #e56966;
}

@supports (not (-ms-ime-align: auto)) {
  .placeholder {
    position: absolute;
    transform: translate(15px, -28px);
    transition: all 0.2s ease-out;
    z-index: -1;
  }

  .input:focus + .placeholder,
  .input:not(:placeholder-shown) + .placeholder {
    transform: translate(16px, -64px);
    font-size: 12px;
    background-color: #fafafa;
    width: fit-content;
    padding: 10px 4px;
    z-index: 1;
  }
}

.inputWrapper {
  position: relative;

  &.hasInput {
    // .inputLabel {
    //   font-size: 12px;
    //   color: #181818;
    //   display: block;
    //   position: absolute;
    //   left: 20px;
    //   top: 6px;
    //   background-color: #ffffff;
    //   padding: 0 5px;
    //   z-index: 2;
    // }

    // //input {
    // //  padding-top: 16px;
    // //}
  }
  input {
    border: 1px solid #c5c5c5;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;

    background: transparent;
    width: 100%;
    margin-top: 16px;
    //margin-bottom: 10px;

    appearance: none;
    font-family: Mont, sans-serif;
    max-width: 100%;

    height: 56px;
    padding: 0 20px 0 16px;

    color: #181818;

    transition: 0.25s ease-in-out;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    -moz-appearance: textfield; /* Firefox */

    &.inputUser {
      background: url(/images/form-user-icon.svg) 20px center/20px no-repeat
        #fff;
    }

    &.inputPhone {
      background: url(/images/form-phone-icon.svg) 20px center/16px no-repeat
        #fff;
    }

    &.inputEmail {
      background: url(/images/form-email-icon.svg) 20px center/20px no-repeat
        #fff;
    }

    &.inputStreet {
      background: url(/images/form-addresss-icon.svg) 20px center/16px no-repeat
        #fff !important;
    }

    &.cardInfo {
      background: url(/images/form-cc-icon.svg) 20px center/22px no-repeat #fff;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px #fff !important;
    }

    &:focus {
      outline: 0;
      border-color: #7badd3;
      box-shadow: inset 0 4px 8px rgba(160, 182, 182, 0.32);
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}

.errorMessage {
  display: block;
  color: var(--danger-color);
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  transition: 0.4s;
}

.formRowSingle {
  margin-bottom: 16px;
}

// .inputLabelError {
//   font-size: 12px;
//   color: #e56966;
//   display: block;
//   position: absolute;
//   left: 20px;
//   top: 6px;
//   background-color: #fafafa;
//   padding: 0 5px;
//   z-index: 2;
//   transition: 0.4s;
// }

.inputInvalid {
  border: 1px solid #e56966 !important;
}

.containerRadioButton {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioButton {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eef7ee;
  border-radius: 50%;
  border: 1px solid var(--vitaliv-green-color);
}

.radioButton:checked ~ .checkmark {
  left: 1px;
  height: 18px;
  width: 18px;
  background-color: var(--vitaliv-green-color);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radioButton:checked ~ .checkmark:after {
  display: block;
}

.textRadio {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.015em;
  margin-top: -3px;
}

.fullWidth {
  width: 100% !important;
}

.inputWithRadio {
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 15px;
  background: transparent;
}

.textAreaField {
  resize: none;
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.015em;

  background: transparent;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 10px;

  appearance: none;
  font-family: Mont, sans-serif;
  max-width: 100%;
  height: 100px;
  padding: 10px 20px 0 16px;

  color: #181818;
}

.buttonShowPass {
  position: absolute;
  right: 20px;
  top: 32px;
}

.textAreaFieldPopup {
  width: 460px!important;
}
